<template>
  <div class="container-xl">
    <div class="d-flex my-2">
      <button type="button" class="btn btn-success m-1 btn-lg" data-bs-toggle="modal" data-bs-target="#createExamModal">
        Adicionar
      </button>
      <button type="button" class="btn btn-warning m-1 btn-lg" @click="update" data-bs-toggle="modal"
        data-bs-target="#editExamModal" :disabled="!oneRowSelected">
        Editar
      </button>
      <button type="button" class="btn btn-danger m-1 btn-lg" @click="remove" data-bs-toggle="modal"
        data-bs-target="#deleteExamModal" :disabled="!oneRowSelected">
        Deletar
      </button>
      <div class="input-group m-1">
        <button type="button" class="btn btn-primary btn-lg text-light" @click="updateStatus"
          :disabled="!rowsAreSelected">
          Atualizar Status
        </button>
        <select class="form-select updateStatus border-primary text-primary border-start-0 fit-content"
          :disabled="!rowsAreSelected" v-model="updatedStatus">
          <option value="0">Na fila</option>
          <option value="1">Pendente</option>
          <option value="2">Não compareceu</option>
          <option value="4">Agendado</option>
          <option value="3">Realizado</option>
        </select>
      </div>
      <div class="input-group m-1">
        <button type="button" class="btn btn-primary btn-lg text-light" @click="updateDesc()"
          :disabled="!rowsAreSelected">
          Atualizar Descrição
        </button>
        <select class="form-select updateStatus border-primary text-primary border-start-0 fit-content"
          :disabled="!rowsAreSelected" v-model="updatedDesc">
          <option value="0">Exame</option>
          <option value="1">Consulta</option>
          <option value="2">Cirurgia</option>
        </select>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <a class="nav-link" :class="{ active: descricao == 0 }" @click="mudarDesc(0)">Exame</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: descricao == 1 }" @click="mudarDesc(1)">Consulta</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: descricao == 2 }" @click="mudarDesc(2)">Cirurgia</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: descricao == 3 }" @click="mudarDesc(3)">Todos</a>
      </li>
    </ul>
    <DataTable class="display" id="dataTable" :columns="columns" :data="data" :options="options" ref="table" />

    <QueueCreate></QueueCreate>
    <QueueEdit ref="queueEditRef" :refs="selectedRefs"></QueueEdit>
    <QueueDelete ref="queueDeleteRef" :doc="selectedDoc"></QueueDelete>
  </div>
</template>
<script setup>
import { ref, onMounted, inject } from "vue";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  doc,
  getDoc,
  writeBatch,
  serverTimestamp,
  where
} from "firebase/firestore";
import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net";
import QueueCreate from "../components/QueueCreate.vue";
import QueueEdit from "../components/QueueEdit.vue";
import QueueDelete from "../components/QueueDelete.vue";
import "datatables.net-select";

DataTable.use(DataTablesLib);

const database = inject("firestore");

const options = {
  language: {
    url: "//cdn.datatables.net/plug-ins/1.13.5/i18n/pt-BR.json",
    info: "_START_ - _END_  Total: _TOTAL_ ",
    select: {
      rows: {
        0: "",
      },
      cells: {
        0: "",
      },
      columns: {
        0: "",
      },
    },
  },
  select: true,
  columnDefs: [
    { visible: false, targets: 5 },
    { visible: false, targets: 6 },
    { visible: false, targets: 7 },
  ],

};
let dt;
const data = ref([]);
const table = ref();
const oneRowSelected = ref(false);
const rowsAreSelected = ref(false);
const selectedRowData = ref("");
const updatedStatus = ref(0);
const updatedDesc = ref(0);
const queueEditRef = ref(null);
const queueDeleteRef = ref(null);
const descricao = ref(0);
let unsubscribe;
const selectedRefs = ref({
  examRef: {},
  pacientRef: {},
});
const selectedDoc = ref({
  id: "",
  text: "",
});
const columns = [
  {
    data: "a",
    title: "Pos.",
  },
  {
    data: "b",
    title: "Nome",
  },
  {
    data: "c",
    title: "CPF",
  },
  {
    data: "d",
    title: "SUS",
  },
  {
    data: "e",
    title: "Exame",
  },
  {
    data: "f",
    title: "id",
  },
  {
    data: "g",
    title: "pacientId",
  },
  {
    data: "h",
    title: "statusId",
  },
  {
    data: "i",
    title: "Status",
  },
  {
    data: "j",
    title: "Emergencial",
  },
];

//Função que carrega a tabela com os exames de acordo com a descrição ordenado por data de entrada
const carregarTabela = () => {
  data.value = [];
  if (unsubscribe) {
    console.log("Unsubscribing");
    unsubscribe();
    unsubscribe = null;
  }
  let q = query(collection(database, "exames"), where("descricao", "==", descricao.value), orderBy('emergencial', 'desc'), orderBy("entryDate"));
  if (descricao.value == 3)
    q = query(collection(database, "exames"), orderBy('emergencial', 'desc'), orderBy("entryDate"));
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    oneRowSelected.value = false;
    rowsAreSelected.value = false;
    data.value = [];
    let counter = 1;
    querySnapshot.forEach((doc) => {
      const dados = doc.data();
      let posicao;
      let statusName;
      switch (dados.status) {
        case 0:
          statusName = "Na fila";
          posicao = counter;
          counter++;
          break;
        case 1:
          statusName = "Pendente";
          posicao = counter;
          counter++;
          break;
        case 2:
          statusName = "Não Compareceu";
          posicao = counter;
          counter++;
          break;
        case 4:
          statusName = "Agendado";
          posicao = counter;
          counter++;
          break;
        case 3:
          statusName = "Realizado";
          posicao = 990000 + counter;
          break;
        default:
          statusName = "Error";
          posicao = counter;
          counter++;
          break;
      }
      if (!dados.hiddenExam) {
        dados.hiddenExam = dados.examType.replace(/\S/g, "#");
        dados.hiddenExam =
          dados.examType.substring(0, 5) +
          dados.hiddenExam.substring(4, 7) +
          "...";
      }
      if (!dados.emergencial)
        dados.emergencial = false;
      data.value.push({
        a: Number(posicao),
        b: dados.hiddenName,
        c: dados.hiddenCpf,
        d: dados.hiddenSus,
        e: dados.hiddenExam,
        f: doc.id,
        g: dados.pacientId,
        h: dados.status,
        i: statusName,
        j: dados.emergencial ? "Sim" : "Não",
      });
    });
  });
}


const mudarDesc = (desc) => {
  if (descricao.value == desc) {
    return;
  }
  descricao.value = desc;
  carregarTabela();
};

// Initial data

// Get a DataTables API reference
onMounted(function () {
  carregarTabela();
  dt = table.value.dt;
  dt.on("select", function (e, dt) {
    var count = dt.rows({ selected: true }).count();
    rowsAreSelected.value = true;
    if (count == 1) {
      oneRowSelected.value = true;
    } else {
      oneRowSelected.value = false;
    }
  });
  dt.on("deselect", function () {
    oneRowSelected.value = false;
    rowsAreSelected.value = false;
  });
});

// Add new rows - note how the data object in Vue is changed and the DataTable will reflect
// those changes, rather than using the DataTables API to manipulate the rows.

// For each selected row just add an indicator to show that it's data has been updated
async function update() {
  selectedRowData.value = dt.row(".selected").data();
  const examRef = doc(database, "exames", selectedRowData.value.f);
  const pacientRef = doc(database, "pacientes", selectedRowData.value.g);
  selectedRefs.value.examRef = await getDoc(examRef);
  selectedRefs.value.pacientRef = await getDoc(pacientRef);
  queueEditRef.value.loadDocument();
}

async function updateStatus() {
  // Get a new write batch
  const batch = writeBatch(database);
  dt.rows({ selected: true }).every(function () {
    let row = this.data();
    const exameRef = doc(database, "exames", row.f);
    if (updatedStatus.value != 3) {
      batch.update(exameRef, {
        status: Number(updatedStatus.value),
        updatedAt: serverTimestamp(),
      });
    } else {
      batch.update(exameRef, {
        status: Number(updatedStatus.value),
        doneAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    }
  });
  await batch.commit();
}

async function updateDesc() {
  // Get a new write batch
  const batch = writeBatch(database);
  dt.rows({ selected: true }).every(function () {
    let row = this.data();
    const exameRef = doc(database, "exames", row.f);
    batch.update(exameRef, {
      descricao: Number(updatedDesc.value),
      updatedAt: serverTimestamp(),
    });
  });
  await batch.commit();
}

// For each selected row find the data object in `data` array and remove it
async function remove() {
  selectedRowData.value = dt.row(".selected").data();
  selectedDoc.value.text =
    "Você tem certeza que deseja deletar '" + selectedRowData.value.e + "' ?";
  selectedDoc.value.id = selectedRowData.value.f;
  queueDeleteRef.value.loadDelete();
}
</script>

<style scoped>
/* @import 'bootstrap'; */
/* @import 'datatables.net-bs5'; */
@import "datatables.net-dt";

button:disabled {
  opacity: 0.3;
}

.form-select:disabled {
  opacity: 0.3;
}

.updateStatus {
  width: fit-content !important;
  flex: none !important;
}

.nav-link {
  cursor: pointer !important;
}
</style>
