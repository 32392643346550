<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="deleteExamModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-bg-danger">
          <h1 class="modal-title fs-5">Deletar</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">{{ modalBody }}</div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
            @click="deleteDocument"
          >
            Deletar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { deleteDoc, doc } from "firebase/firestore";
const props = defineProps(["doc"]);
const db = inject("firestore");
const modalBody = ref();
let document
const deleteDocument = async () => {
  await deleteDoc(doc(db, "exames", document.id));
};
const loadDelete = async () => {
  document = props.doc;
  modalBody.value = document.text;
};

defineExpose({
  loadDelete,
});
</script>