<template>
  <!-- Modal -->
  <div class="modal fade" id="editExamModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-fullscreen-xl-down">
      <div class="modal-content">
        <div class="modal-header text-bg-warning">
          <h1 class="modal-title fs-5">Editar</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="editDocument()">
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="" class="form-label">Tipo de Exame</label>
                  <input type="text" required v-model="examType" class="form-control" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Data de entrada na fila</label>
                  <input type="datetime-local" required v-model="entryDate" class="form-control" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Status</label>
                  <select class="form-select" aria-label="Default select example" v-model="status">
                    <option value="0">Na fila</option>
                    <option value="1">Pendente</option>
                    <option value="2">Não compareceu</option>
                    <option value="4">Agendado</option>
                    <option value="3">Realizado</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Descrição</label>
                  <select class="form-select" v-model="descricao">
                    <option value="0">Exame</option>
                    <option value="1">Consulta</option>
                    <option value="2">Cirurgia</option>
                  </select>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="emergencial" />
                    <label class="form-check-label" for="flexCheckDefault">
                      Procedimento Emergencial
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="" class="form-label">CPF do Paciente</label>
                  <input required readonly disabled type="text" v-model="cpf" class="form-control" maxlength="14" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Cartão do SUS</label>
                  <input required type="text" v-model="susCard" class="form-control" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Nome</label>
                  <input required type="text" v-model="name" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancelar
            </button>
            <button :disabled="isFormInvalid" type="submit" class="btn btn-warning" data-bs-dismiss="modal">
              Editar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed } from "vue";
import { serverTimestamp, doc, setDoc } from "firebase/firestore";
const props = defineProps(["refs"]);
const db = inject("firestore");
const examType = ref("");
const entryDate = ref("");
const cpf = ref("");
const descricao = ref(0);
const emergencial = ref(false);
const status = ref(0);
const susCard = ref("");
const name = ref("");
let examRef;
let pacientRef;
let hiddenName;
let hiddenCpf;
let hiddenSus;

const editDocument = async () => {
  await setDoc(
    doc(db, "pacientes", pacientRef.id),
    {
      cpf: cpf.value,
      susCard: susCard.value,
      name: name.value,
      updatedAt: serverTimestamp(),
    },
    { merge: true }
  );
  hiddenCpf = cpf.value.replace(/[0-9]/g, "#");
  hiddenCpf = cpf.value.substring(0, 4) + hiddenCpf.substring(4, 7) + "...";

  hiddenSus = susCard.value.replace(/[0-9]/g, "#");
  hiddenSus = susCard.value.substring(0, 4) + hiddenSus.substring(4, 7) + "...";

  hiddenName = name.value.replace(/\S/g, "#");
  hiddenName = name.value.substring(0, 4) + hiddenName.substring(4, 7) + "...";

  let examData;
  if (Number(status.value) == 1)
    examData = {
      examType: examType.value,
      entryDate: entryDate.value,
      pacientId: pacientRef.id,
      status: Number(status.value),
      descricao: Number(descricao.value),
      emergencial: emergencial.value,
      hiddenCpf: hiddenCpf,
      hiddenSus: hiddenSus,
      hiddenName: hiddenName,
      updatedAt: serverTimestamp(),
      doneAt: serverTimestamp(),
    };
  else {
    examData = {
      examType: examType.value,
      entryDate: entryDate.value,
      pacientId: pacientRef.id,
      status: Number(status.value),
      descricao: Number(descricao.value),
      emergencial: emergencial.value,
      hiddenCpf: hiddenCpf,
      hiddenSus: hiddenSus,
      hiddenName: hiddenName,
      updatedAt: serverTimestamp(),
    };
  }
  await setDoc(doc(db, "exames", examRef.id), examData, { merge: true });
  console.log("Exam written with ID: ", examRef.id);
};

const isFormInvalid = computed(() => {
  if (
    !examType.value ||
    !entryDate.value ||
    !cpf.value ||
    !susCard.value ||
    !name.value
  ) {
    return true;
  } else {
    return false;
  }
});

const loadDocument = async () => {
  examRef = props.refs.examRef;
  pacientRef = props.refs.pacientRef;
  let examData = examRef.data();
  let pacientData = pacientRef.data();
  examType.value = examData.examType;
  entryDate.value = examData.entryDate;
  status.value = examData.status;
  cpf.value = pacientData.cpf;
  susCard.value = pacientData.susCard;
  name.value = pacientData.name;
  console.log(examData.descricao);
  if (examData.descricao)
    descricao.value = examData.descricao;
  if (examData.emergencial)
    emergencial.value = examData.emergencial;
};

defineExpose({
  loadDocument,
});
</script>
