import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//Bootstrap
import './style/css/main.min.css'
import "bootstrap"
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css"
//Firebase
import { initializeApp  } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId,
    measurementId: process.env.VUE_APP_measurementId
  }
const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const app =  createApp(App)
app.use(router)
app.provide('firebaseApp',firebaseApp)
app.provide('firestore',firestore)
app.mount('#app')
