<template>
  <nav class="navbar bg-primary navbar-expand-lg">
    <div class="container-fluid">
      <router-link to="/dashboard/home" class="navbar-brand text-light fs-3 pe-3 border-end border-light"
        >Form Fila Saúde</router-link
      >
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link fs-4" to="/dashboard/home" active-class="active"
            >Dashboard</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link fs-4"
            to="/dashboard/report"
            active-class="active"
            >Relatórios</router-link
          >
        </li>
      </ul>

      <button
        class="btn btn-outline-light"
        aria-current="page"
        href="#"
        @click="handleSignOut"
      >
        Sair
      </button>
    </div>
  </nav>
  <router-view/>
</template>

<script setup>
import { useRouter } from "vue-router";

import { getAuth, signOut } from "firebase/auth";

// import QueueTable from "../components/QueueTable.vue";
// import QueueEdit from "../components/QueueEdit.vue";
const auth = getAuth();
const router = useRouter();
const handleSignOut = () => {
  signOut(auth).then(() => {
    router.push("/login");
  });
};
</script>

<style scoped>
.navbar-brand{
  font-family: montserratBold;
}
.nav-link{
  font-family: montserrat;
  color:white
}
.active{
  font-family: montserratSemibold;
  color:white !important
}
</style>