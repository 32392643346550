import { createRouter, createWebHashHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SignIn from "../views/SignIn.vue";
import QueueView from "../views/QueueView.vue";
import QueueDashboard from "../views/QueueDashboard.vue";
import QueueTable from "../components/QueueTable.vue";
import QueueReport from "../components/QueueReport.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", component: QueueView },
    { path: "/login", component: SignIn },
    {
      path: "/dashboard",
      component: QueueDashboard,
      meta: {
        requiresAuth: true,
      },
      children: [
        { path: "home", component: QueueTable },
        { path: "report", component: QueueReport },
      ],
    },
  ],
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
