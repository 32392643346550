<template>
  <!-- Modal -->
  <div class="modal fade" id="createExamModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-fullscreen-xl-down">
      <div class="modal-content">
        <div class="modal-header text-bg-success">
          <h1 class="modal-title fs-5">Adicionar</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="addDocument()">
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="" class="form-label">Tipo de Exame</label>
                  <input type="text" required v-model="examType" class="form-control" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Data de entrada na fila</label>
                  <input type="datetime-local" required v-model="entryDate" class="form-control" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Status</label>
                  <select class="form-select" v-model="status">
                    <option value="0">Na fila</option>
                    <option value="1">Pendente</option>
                    <option value="2">Não compareceu</option>
                    <option value="4">Agendado</option>
                    <option value="3">Realizado</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Descrição</label>
                  <select class="form-select" v-model="descricao">
                    <option value="0">Exame</option>
                    <option value="1">Consulta</option>
                    <option value="2">Cirurgia</option>
                  </select>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="emergencial" />
                    <label class="form-check-label" for="flexCheckDefault">
                      Procedimento Emergencial
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="" class="form-label">CPF do Paciente</label>
                  <input required type="text" @input="formatCPF()" v-model="cpf" class="form-control" maxlength="14" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Cartão do SUS</label>
                  <input required type="text" :disabled="cpfIsIncomplete" v-model="susCard" class="form-control" />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label">Nome</label>
                  <input required type="text" :disabled="cpfIsIncomplete" v-model="name" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancelar
            </button>
            <button :disabled="isFormInvalid" type="submit" class="btn btn-success" data-bs-dismiss="modal">
              Adicionar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div id="toastUserCpf" class="toast align-items-center border-0" :class="[`text-bg-${toastColor}`]" role="alert"
      aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          {{ toastUserCpfText }}
        </div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed, watch, onMounted } from "vue";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  getDocs,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
const db = inject("firestore");
import { Toast } from "bootstrap";

const examType = ref("");
const entryDate = ref("");
const toastColor = ref("success");
const cpf = ref("");
const status = ref(0);
const cpfIsIncomplete = ref(true);
const susCard = ref("");
const name = ref("");
const descricao = ref(0);
const emergencial = ref(false);
let toastUserCpf;
let toastUserCpfText;
let hiddenName;
let hiddenCpf;
let hiddenSus;
let pacientRef;

onMounted(function () {
  const toastUserCpfElement = document.getElementById("toastUserCpf");
  toastUserCpf = Toast.getOrCreateInstance(toastUserCpfElement);
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  /* remove second/millisecond if needed - credit ref. https://stackoverflow.com/questions/24468518/html5-input-datetime-local-default-value-of-today-and-current-time#comment112871765_60884408 */
  now.setMilliseconds(null)
  now.setSeconds(null)
  entryDate.value = now.toISOString().slice(0, -1);
});

const formatCPF = () => {
  //retira os caracteres indesejados...
  let cpfAtual = cpf.value;
  cpfAtual = cpfAtual.replace(/[^\d]/g, "");
  cpf.value = cpfAtual.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

function clearForm() {
  examType.value = "";
  entryDate.value = "";
  cpf.value = "";
  cpfIsIncomplete.value = true;
  susCard.value = "";
  name.value = "";
  status.value = 0;
  descricao.value = 0;
  hiddenCpf = undefined;
  hiddenName = undefined;
  hiddenSus = undefined;
  pacientRef = undefined;
  emergencial.value = false;
}

const addDocument = async () => {
  const examesRef = collection(db, "exames");
  const pacientesRef = collection(db, "pacientes");
  if (pacientRef) {
    await setDoc(
      doc(db, "pacientes", pacientRef.id),
      {
        cpf: cpf.value,
        susCard: susCard.value,
        name: name.value,
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    );
  } else {
    const pacient = {
      cpf: cpf.value,
      susCard: susCard.value,
      name: name.value,
      createdAt: serverTimestamp(),
    };
    pacientRef = await addDoc(pacientesRef, pacient);
  }
  await setDoc(
    doc(db, "pacientes", pacientRef.id),
    {
      id: pacientRef.id,
    },
    { merge: true }
  );
  hiddenCpf = cpf.value.replace(/[0-9]/g, "#");
  hiddenCpf = cpf.value.substring(0, 5) + hiddenCpf.substring(4, 7) + "...";

  hiddenSus = susCard.value.replace(/[0-9]/g, "#");
  hiddenSus = susCard.value.substring(0, 5) + hiddenSus.substring(4, 7) + "...";

  hiddenName = name.value.replace(/\S/g, "#");
  hiddenName = name.value.substring(0, 5) + hiddenName.substring(4, 7) + "...";

  const exam = {
    examType: examType.value,
    entryDate: entryDate.value,
    pacientId: pacientRef.id,
    status: Number(status.value),
    descricao: Number(descricao.value),
    emergencial: emergencial.value,
    hiddenCpf: hiddenCpf,
    hiddenSus: hiddenSus,
    hiddenName: hiddenName,
    createdAt: serverTimestamp(),
  };
  const examRef = await addDoc(examesRef, exam);
  console.log("Exam written with ID: ", examRef.id);
  clearForm();
};

const isFormInvalid = computed(() => {
  if (!examType.value || !entryDate.value || !cpf.value) {
    return true;
  } else {
    return false;
  }
});

watch(cpf, async (newCpf) => {
  if (toastUserCpf.isShown()) {
    toastUserCpf.hide();
  }
  console.log("toast = " + toastUserCpf.isShown());
  const pacientesRef = collection(db, "pacientes");
  if (newCpf.length == 14) {
    const q = query(pacientesRef, where("cpf", "==", newCpf));
    const querySnapshot = await getDocs(q);
    cpfIsIncomplete.value = false;
    if (querySnapshot.docs.length == 0) {
      toastUserCpfText = "Paciente novo, digite os dados!";
      toastColor.value = "warning";
    } else {
      querySnapshot.forEach((doc) => {
        pacientRef = doc;
        susCard.value = doc.data().susCard;
        toastUserCpfText = "Paciente encontrado '" + doc.data().name + "'!";
        name.value = doc.data().name;
      });
      toastColor.value = "success";
    }
    toastUserCpf.show();
  } else {
    cpfIsIncomplete.value = true;
    susCard.value = "";
    name.value = "";
  }
});
</script>
<style scoped>
button:disabled {
  opacity: 0.3;
}
</style>