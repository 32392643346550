<template>
  <div class="container-xl my-3">
    <div class="d-flex my-2">
      <button type="button" class="btn btn-primary m-1 btn-lg text-light" @click="dia">
        Dia
      </button>
      <button type="button" class="btn btn-primary m-1 btn-lg text-light" @click="semana">
        Semana
      </button>
      <button type="button" class="btn btn-primary m-1 btn-lg text-light" @click="mes">
        Mês
      </button>
      <button type="button" class="btn btn-primary btn-lg m-1 text-light" @click="ano">
        Ano
      </button>
    </div>
    <DataTable class="display" id="dataTable" :columns="columns" :data="data" :options="options" ref="table" />
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from "vue";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  Timestamp,
  getDocs,
} from "firebase/firestore";
import "jszip";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import "datatables.net-select";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5";
DataTable.use(DataTablesCore);

const database = inject("firestore");

const options = {
  language: {
    url: "//cdn.datatables.net/plug-ins/1.13.5/i18n/pt-BR.json",
    info: "_START_ - _END_  Total: _TOTAL_ ",
    select: {
      rows: {
        0: "",
      },
      cells: {
        0: "",
      },
      columns: {
        0: "",
      },
    },
  },
  dom: "Bftip",
  buttons: ["copy", "pdf"]
};
// let dt;
const data = ref([]);
const table = ref();
const columns = [
  {
    data: "a",
    title: "Estado",
  },
  {
    data: "b",
    title: "Nome",
  },
  {
    data: "c",
    title: "CPF",
  },
  {
    data: "d",
    title: "SUS",
  },
  {
    data: "e",
    title: "Exame",
  },
];
let date = new Date();
let timestamp = Timestamp.fromDate(date);
let q
let unsubscribe
const pacientMap = new Map();
// Initial data

// Get a DataTables API reference
onMounted(function () {
  dia()
  // dt = table.value.dt;
});

function dia() {
  console.log("dia")
  date = new Date();
  date.setDate(date.getDate() - 1);
  timestamp = Timestamp.fromDate(date);
  console.log(date.toISOString())
  resetTable();
}
function semana() {
  console.log("semana")
  date = new Date();
  date.setDate(date.getDate() - 7);
  timestamp = Timestamp.fromDate(date);
  console.log(date.toISOString())
  resetTable();
}
function mes() {
  console.log("mes")
  date = new Date();
  date.setMonth(date.getMonth() - 1);
  timestamp = Timestamp.fromDate(date);
  console.log(date.toISOString())
  resetTable();
}
function ano() {
  console.log("ano")
  date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  timestamp = Timestamp.fromDate(date);
  console.log(date.toISOString())
  resetTable();
}
async function resetTable() {
  if (unsubscribe)
    unsubscribe();
  q = query(
    collection(database, "exames"),
    where("doneAt", ">=", timestamp),
    where("status", "==", 3),
    orderBy("doneAt")
  );
  const querySnapshot = await getDocs(collection(database, "pacientes"));
  querySnapshot.forEach(async (document) => {
    // doc.data() is never undefined for query doc snapshots
    pacientMap.set(document.id, document.data())
  });
  let pacientRefArray = [];
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    data.value = [];
    let counter = 1;
    querySnapshot.forEach((doc) => {
      let pacientData = {
        name: "Procurando...",
        cpf: "Procurando...",
        susCard: "Procurando..."
      }

      const dados = doc.data();
      if (pacientMap.has(dados.pacientId)) {
        pacientData = pacientMap.get(dados.pacientId)
      } else {
        pacientRefArray.push(dados.pacientId)
      }
      let posicao;
      switch (dados.status) {
        case 0:
          posicao = counter;
          break;
        case 3:
          posicao = "Realizado";
          break;
        default:
          posicao = "Desatualizado";
          break;
      }
      data.value.push({
        a: posicao,
        b: pacientData.name,
        c: pacientData.cpf,
        d: pacientData.susCard,
        e: dados.examType,
      });
      counter++;
    });
  });



}
</script>

<style scoped>
@import "datatables.net-dt";
@import "datatables.net-buttons-dt";
</style>