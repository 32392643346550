<template>
  <div class="container-xl mt-2">
    <div class="d-flex justify-content-between pref-topbar">
      <h1 class="text-primary pref-arrows">
        <i class="bi pref-arrows bi-arrow-right-circle-fill text-primary"></i>
        <i class="bi bi-arrow-right-circle-fill text-primary"></i>
      </h1>
      <div class="">
        <img class="img-fluid pref-img" src="../assets/logoed_pref.png" alt="" />
      </div>
    </div>
    <p class="text-primary display-5 pref-lista-titulo">
      Lista de <span class="pref-text-black">Espera</span>
    </p>
    <h3 class="text-primary pref-text">
      A Prefeitura Municipal de Pirapetinga apresenta a LISTA DE ESPERA dos
      pacientes que aguardam por consultas, exames e intervenções cirúrgicas e
      outros procedimentos da rede pública de saúde do Município, em atendimento
      à <span class="pref-text-bold">Lei Nº 1841/2021</span> de 21 de abril de
      2021.
    </h3>
    <h4 class="text-primary pref-subtext mt-3">
      Obs: A mensagem “Última atualização”, é um indicativo de que houve
      movimentação na fila, tanto para exames realizados quanto para novos
      exames.
    </h4>
    <hr class="text-primary opacity-75" />
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <a class="nav-link" :class="{ active: descricao == 0 }" @click="mudarDesc(0)">Exame</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: descricao == 1 }" @click="mudarDesc(1)">Consulta</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: descricao == 2 }" @click="mudarDesc(2)">Cirurgia</a>
      </li>
    </ul>
    <DataTable class="display responsive nowrap" :columns="columns" :data="data" :options="options" ref="table" />
    <h5 class="text-primary pref-update">
      Última atualização: {{ lastUpdatedAt }}
    </h5>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import { collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import { Tooltip } from "bootstrap";
import DataTable from "datatables.net-vue3";
import DataTablesLib from "datatables.net";
import "datatables.net-responsive";
DataTable.use(DataTablesLib);
const descricao = ref(0);
const database = inject("firestore");
let lastUpdatedAtTimestamp = 0;
let unsubscribe;
const lastUpdatedAt = ref("");
const options = {
  language: {
    url: "//cdn.datatables.net/plug-ins/1.13.5/i18n/pt-BR.json",
    info: "_START_ - _END_  Total: _TOTAL_",
    select: {
      rows: {
        0: "",
      },
      cells: {
        0: "",
      },
      columns: {
        0: "",
      },
    },
  },
  responsive: true,
  columnDefs: [
    { targets: 0, responsivePriority: 1 },
    { targets: 1, responsivePriority: 2 },
    { targets: 2, responsivePriority: 3 },
    { targets: 3, responsivePriority: 5 },
    { targets: 4, responsivePriority: 4 },
  ],
  rowCallback: (row, data) => {
    //Adicionando fundo vermelho caso status seja igual a 2
    if (data.f == "Não Compareceu") {
      row.style.backgroundColor = "#ffcccc";
    }
  }
};

const mudarDesc = (desc) => {
  if (descricao.value == desc) return;
  descricao.value = desc;
  atualizarTabela();
};

const atualizarTabela = () => {
  data.value = [];
  if (unsubscribe) {
    unsubscribe();
    unsubscribe = null;
  }
  let q = query(collection(database, "exames"), where("descricao", "==", descricao.value), orderBy('emergencial', 'desc'), orderBy("entryDate"));
  unsubscribe = onSnapshot(q, async (querySnapshot) => {
    data.value = [];
    let counter = 1;
    await querySnapshot.forEach((doc) => {
      const dados = doc.data();
      let posicao;
      let statusName;
      switch (dados.status) {
        case 0:
          statusName = "Na fila";
          posicao = counter;
          break;
        case 1:
          statusName = "Pendente";
          posicao = counter;
          break;
        case 2:
          statusName = "Não Compareceu";
          posicao = counter;
          break;
        case 4:
          statusName = "Agendado";
          posicao = counter;
          break;
        case 3:
          statusName = "Realizado";
          posicao = 900000 + counter;
          counter--;
          break;
        default:
          statusName = "Erro";
          posicao = counter;
          break;
      }
      if (dados.createdAt.toMillis() > lastUpdatedAtTimestamp) {
        lastUpdatedAtTimestamp = dados.createdAt.toMillis();
        lastUpdatedAt.value = formatDate(new Date(lastUpdatedAtTimestamp));
      }
      if (dados.updatedAt)
        if (dados.updatedAt.toMillis() > lastUpdatedAtTimestamp) {
          lastUpdatedAtTimestamp = dados.updatedAt.toMillis();
          lastUpdatedAt.value = formatDate(new Date(lastUpdatedAtTimestamp));
        }
      if (!dados.hiddenExam) {
        dados.hiddenExam = dados.examType.replace(/\S/g, "#");
        dados.hiddenExam =
          dados.examType.substring(0, 5) +
          dados.hiddenExam.substring(4, 7) +
          "...";
      }
      if (dados.emergencial) {
        statusName += ' <i class="bi bi-exclamation-circle-fill text-danger" data-bs-toggle="tooltip" data-bs-title="Procedimento Emergencial"></i>'
      }
      data.value.push({
        a: posicao,
        b: dados.hiddenName,
        c: dados.hiddenCpf,
        d: dados.hiddenSus,
        e: dados.hiddenExam,
        f: statusName,
      });
      counter++;
    });
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    if (tooltipTriggerList.length > 0) {
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
      console.log(tooltipList)
    }
  });
}

const data = ref([]);
const table = ref();
const columns = [
  {
    data: "a",
    title: "Pos.",
  },
  {
    data: "b",
    title: "Nome",
  },
  {
    data: "c",
    title: "CPF",
  },
  {
    data: "d",
    title: "SUS",
  },
  {
    data: "e",
    title: "Exame",
  },
  {
    data: "f",
    title: "Estado",
  },
];

function formatDate(objectDate) {
  let formattedDate = "";
  let dd = objectDate.getDate();
  let MM = objectDate.getMonth();
  MM++;
  let yyyy = objectDate.getFullYear();
  let hh = objectDate.getHours();
  let min = objectDate.getMinutes();
  let ss = objectDate.getSeconds();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (MM < 10) {
    MM = "0" + MM;
  }
  if (hh < 10) {
    hh = "0" + hh;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (ss < 10) {
    ss = "0" + ss;
  }
  formattedDate =
    hh + ":" + min + ":" + ss + " - " + dd + "/" + MM + "/" + yyyy;
  return formattedDate;
}

onMounted(() => {
  atualizarTabela();
});
</script>

<style scoped>
/* @import 'bootstrap';
@import 'datatables.net-bs5'; */

@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

.pref-img {
  height: 100%;
  width: auto;
}

.pref-arrows {
  height: fit-content;
  margin: auto 0;
}

.pref-arrows.text-primary {
  height: fit-content;
}

.pref-topbar {
  height: 5vh;
}

.pref-text {
  font-family: montserrat;
}

.pref-subtext {
  font-family: montserrat;
}

.pref-update {
  font-family: montserratBold;
}

.pref-lista-titulo {
  font-family: montserratLight;
}

.pref-text-black {
  font-family: montserratBlack;
}

.pref-text-bold {
  font-family: montserratBold;
}

.nav-link {
  cursor: pointer !important;
}

@media (max-width: 600px) {
  .pref-text {
    font-family: montserrat;
    font-size: 15px;
  }

  .pref-subtext {
    font-family: montserrat;
    font-size: 13px;
  }

  .pref-update {
    font-family: montserratBold;
    font-size: 14px;
  }

  .pref-lista-titulo {
    font-family: montserratLight;
    font-size: 30px;
  }
}
</style>
