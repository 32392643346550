<template>
  <router-view />
</template>
<style>
@font-face {
  font-family: montserratBlack;
  src: url(assets/Montserrat/static/Montserrat-Black.ttf);
}

@font-face {
  font-family: montserratSemibold;
  src: url(assets/Montserrat/static/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: montserratBold;
  src: url(assets/Montserrat/static/Montserrat-Bold.ttf);
}

@font-face {
  font-family: montserrat;
  src: url(assets/Montserrat/static/Montserrat-Regular.ttf);
}

@font-face {
  font-family: montserratLight;
  src: url(assets/Montserrat/static/Montserrat-Light.ttf);
}
.datatable{
  font-family: montserrat;
  color: #f27222;
}
.dataTables_info,.dataTables_length,.dataTables_filter,.dataTables_paginate{
  font-family: montserratSemibold;
}
thead,.dt-buttons{
  font-family: montserratBold;
}
td{
  color: black;
}
table.display.dataTable>tbody>tr.odd.selected>*,
table.display.dataTable>tbody>tr.selected:hover>* {
  box-shadow: inset 0 0 0 9999px rgba(242, 114, 34) !important;
}

table.display.dataTable>tbody>tr.even.selected>*,
table.display.dataTable>tbody>tr.selected:hover>* {
  box-shadow: inset 0 0 0 9999px rgba(242, 114, 34) !important;
}

table.dataTable.row-border>tbody>tr>th,
table.dataTable.row-border>tbody>tr>td,
table.dataTable.display>tbody>tr>th,
table.dataTable.display>tbody>tr>td {
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
}

:root {
  --dt-row-selected: 242, 114, 34 !important;
}

.btn {
  font-family: montserratSemibold !important
}</style>


