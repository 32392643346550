<template>
  <div class="card py-3 mt-5 mx-auto border-primary border-1 col-sm-6">
  <div class="card-body">
    <h1 class="text-primary login-title">Form Fila Saúde</h1>
    <form @submit="signIn">
      <p class="text-danger" v-if="errorMsg">{{ errorMsg }}</p>
      <div class="mb-3">
        <label class="form-label text-primary login-label">Email</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="email"
          required
        />
      </div>
      <div class="mb-3">
        <label class="form-label text-primary login-label">Senha</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
          required
        />
      </div>
      <button type="submit" class="btn btn-primary text-light">Entrar</button>
    </form>
  </div>
</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useRouter } from "vue-router";
const email = ref("");
const password = ref("");
const errorMsg = ref("");
const router = useRouter();
const signIn = () => {
  console.log(email.value + " - " + password.value);
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then(() => {
      // Signed in
      router.push("/dashboard/home");
      // ...
    })
    .catch(() => {
      errorMsg.value = "O email ou a senha estão incorretos!";
    });
};

onMounted(function () {
  const auth = getAuth();
  if (auth.currentUser) router.push("/dashboard/home");
});
</script>

<style scoped>
  .login-title{
    font-family: montserratBold;
    font-size: 3rem;
  }

  .login-label{
    font-family: montserrat;
    font-size: 1.125rem;
  }
  .btn-primary{
    font-family: montserrat;
  }
</style>